import React from 'react';
import { withRouter } from 'react-router-dom';
import { Verify } from '../../utils/Verify';
import '../../resources/css/General.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faCakeCandles } from '@fortawesome/free-solid-svg-icons/faCakeCandles';
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { faUserGear } from '@fortawesome/free-solid-svg-icons/faUserGear';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple';
import { faBuildingUser } from '@fortawesome/free-solid-svg-icons/faBuildingUser';
import { faClipboardUser } from '@fortawesome/free-solid-svg-icons/faClipboardUser';
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons/faCircleDown';
import { MenuSuperAdminController } from '../controller/MenuSuperAdminController';
class MenuSuperAdminComponent extends React.Component {
	constructor() {
		super();
		this.verify = new Verify();
		this.state = {
			data: []
		};
		this.MenuSuperAdminController = new MenuSuperAdminController();
	}

	componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
	}

	go2cuponForm = (event) => {
		event.preventDefault();
		this.props.history.push('/cuponCreation');
	};

	go2clientGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/clientGrid');
	};

	logout = (event) => {
		event.preventDefault();
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
		sessionStorage.removeItem('role');
		this.props.history.push('/login');
		window.location.reload(false);
	};

	go2scanner = (event) => {
		event.preventDefault();
		sessionStorage.setItem('visited', 0);
		this.props.history.push('/scanner');
	};

	go2birthday = (event) => {
		event.preventDefault();
		this.props.history.push('/birthdayDudes');
	};

	go2cuponGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/cuponGrid');
	};

	go2bussinesAdd = (event) => {
		event.preventDefault();
		this.props.history.push('/bussinesAdd');
	};

	go2businessGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/businessGrid');
	};

	go2QrGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/qrGrid');
	};

	go2zipDownload = (event) => {
		event.preventDefault();
		this.props.history.push('/zipGrid');
	};

	go2Statics = (event) => {
		event.preventDefault();
		if (sessionStorage.getItem('role') === 'sudosu') {
			this.props.history.push('/statistics');
		}
		if (sessionStorage.getItem('role') === 'admin') {
			const Id = sessionStorage.getItem('idBusiness');
			this.MenuSuperAdminController.findBusinessById(Id).then((resp) => {
				this.props.history.push('coupon/' + Id, {
					businessName: resp.data.name,
					businessContact: resp.data.contacEmail
				});
			});
		}
	};

	button(textBtn, classNameBtn, onClickBtn, level, icon) {
		if (this.verify.securityVerify(level)) {
			return (
				<div className={classNameBtn} onClick={onClickBtn}>
					<div>
						<FontAwesomeIcon icon={icon} className='icon' />
					</div>
					{textBtn}
				</div>
			);
		} else {
			return <></>;
		}
	}

	go2EmployeesGrid = (key) => {
		sessionStorage.setItem('typeUser', key);
		this.props.history.push('/usersGrid');
	};

	render() {
		return (
			<div className='container h-100 py-5'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div className='card shadow bg-light text-dark'>
							<div className='card-header'>
								<div>
									<a href='/index' className='btn btn-outline-danger ml-2'>
										{' '}
										Inicio{' '}
									</a>
								</div>
								<h4 className='text-center' id='menutitle'>
									Menú de Opciones{' '}
								</h4>
								<div>
									<button
										type='button'
										className='btn btn-outline-danger ml-2'
										onClick={this.logout}
									>
										{' '}
										Cerrar Sesión{' '}
									</button>
								</div>
							</div>
						</div>
						<div className='card shadow bg-dark text-white align-items-center'>
							<div className='card-body'>
								<div
									className='container-sm align-items-center'
									id='ContainerD'
								>
									<div className='ContainerA'>
										{this.button(
											'Crear Negocios',
											'btnN btn-red btn-md',
											this.go2bussinesAdd,
											'sudosu',
											faBriefcase
										)}
										{this.button(
											'Administrar negocios',
											'btnN btn-gold btn-md',
											this.go2businessGrid,
											'sudosu',
											faBuildingUser
										)}
										{this.button(
											'Generar Cupones',
											'btnN btn-yellow btn-md',
											this.go2cuponForm,
											'admin',
											faBarcode
										)}
										{this.button(
											'Escanear Cupón',
											'btnN btn-lemon btn-md',
											this.go2scanner,
											'employee',
											faExpand
										)}
										{this.button(
											'Editar Qr',
											'btnN btn-green btn-md',
											this.go2QrGrid,
											'admin',
											faQrcode
										)}
										{this.button(
											'Cumpleañeros',
											'btnN btn-redora btn-md',
											this.go2birthday,
											'employee',
											faCakeCandles
										)}
										{this.button(
											'Administrar Empleados',
											'btnN btn-hotPink btn-md',
											() => this.go2EmployeesGrid('employee'),
											'admin',
											faClipboardUser
										)}
										{this.button(
											'Administrar Cupones',
											'btnN btn-violet btn-md',
											this.go2cuponGrid,
											'employee',
											faTicket
										)}
										{this.button(
											'Gestionar Administradores',
											'btnN btn-seaGreen btn-md',
											() => this.go2EmployeesGrid('admin'),
											'sudosu',
											faUserTie
										)}
										{this.button(
											'Administrar Clientes',
											'btnN btn-cyan btn-md',
											this.go2clientGrid,
											'admin',
											faUserGear
										)}
										{this.button(
											'Ver Estadisticas',
											'btnN btn-redora btn-md',
											this.go2Statics,
											'admin',
											faChartSimple
										)}
										{this.button(
											'Descargar Cupones',
											'btnN btn-lemon btn-md',
											this.go2zipDownload,
											'employee',
											faCircleDown
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(MenuSuperAdminComponent);
