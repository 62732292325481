import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class MenuSuperAdminController {
	async findBusinessById(ID) {
		const resp = await Client.GET({
			url: Urls.businessApi.findBusinessById,
			params: {
				id: ID
			}
		});
		return resp;
	}
}
