import React from 'react';
import { CuponFormController } from '../controller/CuponFormController';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Home } from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import { deleteLastOneObject, Utils } from '../../resources/Utils';
import { withRouter } from 'react-router-dom';
import Field from './DinamicFieldComponent';
import { HomePageController } from '../../Index/controller/HomePageController';
import BusinessSelector from '../../resources/BusinessSelector';
import AlertasCuponInc from './AlertasCuponInc';
import AlertasCuponVida from './AlertasCuponVida';
import AlertasCuponNum from './AlertasCuponNum';

const fecha = new Date();

class CuponFormComponent extends React.Component {
	constructor() {
		super();
		this.cuponFormController = new CuponFormController();
		this.homePageController = new HomePageController();
		this.verify = new Verify();
		this.state = {
			cat: [],
			option: '',
			click: 0,
			idProduct: '',
			discount: '',
			description: '',
			isIncrement: false,
			price: '',
			priceDiscount: null,
			lifes: '',
			expirationDate: '',
			cty: '',
			discountFields: [
				<Field key={0} name={0} handleChange={this.handleChangeInput} />
			],
			autoincrement: 1,
			isCheked: false,
			discountMap: {},
			num: sessionStorage.getItem('num')
		};

		this.handleChangeBus = this.handleChangeBus.bind(this);
	}

	async componentDidMount() {
		if (!this.verify.securityVerify('admin')) {
			this.props.history.push('/superAdminMenu');
		}

		this.businessStatus();
	}

	verify1() {
		this.verify.verify();
	}

	async businessStatus() {
		const res = await this.cuponFormController.findQrBusiness(
			sessionStorage.getItem('idBusiness')
		);

		if (res.data.id === -1) {
			setTimeout(() => {
				this.props.history.push('/qrGrid');
			}, 1200);
		}
	}

	handleChangeBus = (idB) => {
		this.businessStatus(idB);
	};

	async download(qrAddress) {
		this.cuponFormController.download(qrAddress.codes);
		this.clear();
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		if (this.state.click === 0) {
			this.setState({ click: 1 });
			const startTime = performance.now();
			const a = await this.cuponFormController.createCoupon(this.state);

			if (a !== null) {
				Utils.swl({
					icon: 'info',
					text: ' Sus cupones se estan generando cuando esten generados los podra descargar en "Descargar cupones"',
					title: 'Espere...'
				});
				this.download(a);
				const endTime = performance.now();
				const duration = endTime - startTime;
				console.log(`El código tardó ${duration} milisegundos.`);
			} else {
				this.setState({ click: 0 });
			}
		} else {
			Utils.swl({
				icon: 'info',
				text: 'Se está procesando una petición.',
				title: 'Espere...'
			});
		}
	};

	clear() {
		this.setState({
			idProduct: '',
			discount: '',
			description: '',
			isIncrement: 0,
			price: '',
			priceDiscount: '',
			lifes: '',
			expirationDate: '',
			cty: '',
			click: 0
		});
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleChangeInput = (event) => {
		this.setState({
			discountMap: {
				...this.state.discountMap,
				[event.target.name]: event.target.value
			}
		});
	};

	changeCheck = (_) => {
		this.setState({
			discountFields: [
				<Field key={0} name={0} handleChange={this.handleChangeInput} />
			]
		});
		this.setState({ autoincrement: 1 });
		this.setState({ isCheked: !this.state.isCheked });
		this.setState({ isIncrement: !this.state.isIncrement });
	};

	addField = (_) => {
		if (this.state.discountFields.length < this.state.lifes) {
			this.setState({
				...this.state,
				autoincrement: this.state.autoincrement + 1
			});
			this.state.discountFields.push(
				<Field
					key={this.state.autoincrement + 1}
					name={this.state.autoincrement}
					handleChange={this.handleChangeInput}
				/>
			);
		} else {
			Utils.swl({
				icon: 'info',
				text: 'La cantidad de descuentos debe ser menor o igual al número de vidas.',
				title: 'Espere...'
			});
		}
	};

	subtractField = (_) => {
		if (this.state.discountFields.length > 1) {
			this.state.discountFields.pop();
			this.setState({
				discountFields: this.state.discountFields,
				discountMap: deleteLastOneObject(this.state.discountFields)
			});
		}
	};

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	fechaMin = (date) => {
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (month < 10) {
			if (day < 10) return `${year}-0${month}-0${day}`;
			else return `${year}-0${month}-${day}`;
		} else {
			if (day < 10) return `${year}-${month}-0${day}`;
			else return `${year}-${month}-${day}`;
		}
	};

	render() {
		return (
			<div className='container-fluid h-100  py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-10 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'>Formulario Cupones</h4>
							</div>
							<div className='card-body mt-3'>
								<div className='container-fluid'>
									<form onSubmit={this.handleSubmit}>
										{sessionStorage.getItem('role') !== 'sudosu' ? (
											<></>
										) : (
											<div className='row'>
												<div className='col-sm-12 col-md-8 col-lg-7 p-2'>
													<BusinessSelector
														handler={this.handleChangeBus}
														selectedB={sessionStorage.getItem('idBusiness')}
													/>
												</div>
											</div>
										)}
										<div className='row'>
											<div className='col-sm-12 col-md-8 col-lg-8 col-xl-7'>
												<div
													className='form-group row'
													hidden={this.state.isCheked}
												>
													<div className='col-12'>
														<label> Porcentaje de Descuento: </label>
													</div>

													<div className='col-10'>
														<input
															className='form-control'
															type='number'
															name='discount'
															max='100'
															min='0'
															pattern='[0-9]{3}'
															placeholder='Porcentaje de Descuento'
															value={
																this.state.isCheked ? '' : this.state.discount
															}
															disabled={this.state.isCheked}
															required
															onChange={this.handleChange}
														/>
													</div>
												</div>
												<div className='form-group row'>
													<div className='col-12'>
														<label>Descripción:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='text'
															name='description'
															placeholder='Descripción'
															maxLength={300}
															value={this.state.description}
															required
															onChange={this.handleChange}
														/>
													</div>
												</div>
												<div className='form-group row'>
													<div className='col-12'>
														<label>Precio:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='number'
															name='price'
															step='0.01'
															max='9999'
															min='0'
															placeholder='Precio'
															value={this.state.price}
															required
															onChange={this.handleChange}
														/>
													</div>
												</div>
												<div
													className='form-group row'
													hidden={this.state.isCheked}
												>
													<div className='col-12'>
														<label>Precio con Descuento:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='number'
															name='priceDiscount'
															step='0.01'
															max='9999'
															min='0'
															placeholder='Precio con Descuento'
															disabled
															value={
																this.state.price -
																this.state.price * (this.state.discount / 100)
															}
															onChange={this.handleChange}
														/>
													</div>
												</div>
												<div className='form-group row'>
													<div className='col-12'>
														<label>Vidas:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='number'
															name='lifes'
															max='100'
															min='1'
															step='1'
															placeholder='Vidas'
															value={this.state.lifes}
															required
															onChange={this.handleChange}
														/>
													</div>
													<div className='col-2'>
														<AlertasCuponVida />
													</div>
												</div>
												<div className='form-group row'>
													<div className='col-12'>
														<label>Fecha de Expiración:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='date'
															name='expirationDate'
															value={this.state.expirationDate}
															required
															min={this.fechaMin(fecha)}
															onChange={this.handleChange}
														/>
													</div>
												</div>
												<div className='form-group row'>
													<div className='col-12'>
														<label>Cantidad de Cupones:</label>
													</div>
													<div className='col-10'>
														<input
															className='form-control'
															type='number'
															name='cty'
															max='9'
															min='1'
															placeholder='Cantidad de Cupones'
															value={this.state.cty}
															step='1'
															required
															onChange={this.handleChange}
														/>
													</div>
													<div className='col-2'>
														<AlertasCuponNum />
													</div>
												</div>
												<div className='form-group row'>
													<div className='skeletonloadbox'>
														<input
															className='btn btn-success btn-md btn-block'
															style={{ width: '100%' }}
															type='submit'
															value='Registrar Cupón'
															onClick={this.verify1()}
														/>
													</div>
												</div>
											</div>
											<div className='col-sm-12 col-md-4 col-lg-4 col-xl-5 px-3'>
												<div className='form-check'>
													<input
														type='checkbox'
														className='form-check-input'
														onChange={this.changeCheck}
														checked={this.state.isCheked}
													/>
													<label className='px-1'>Es incremental?</label>
													<div className='row'>
														<div className='col-2'>
															<button
																type='button'
																className='btn btn-success px-3'
																onClick={this.addField}
															>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='16'
																	height='16'
																	fill='currentColor'
																	className='bi bi-plus-circle'
																	viewBox='0 0 16 16'
																>
																	<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
																	<path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
																</svg>
															</button>
														</div>
														<div className='col-1'></div>
														<div className='col-2'>
															<button
																type='button'
																className='btn btn-danger px-3'
																onClick={this.subtractField}
															>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='16'
																	height='16'
																	fill='currentColor'
																	className='bi bi-dash-circle'
																	viewBox='0 0 16 16'
																>
																	<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
																	<path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
																</svg>
															</button>
														</div>
														<div className='col-1'></div>
														<div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
															<AlertasCuponInc />
														</div>
													</div>
												</div>
												{this.state.isCheked === true ? (
													this.state.discountFields.filter(
														(_, i) => i < this.state.lifes
													)
												) : (
													<></>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(CuponFormComponent);
