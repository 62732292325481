import React from 'react';
import { Utils } from '../../resources/Utils';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Home, ArrowLeft, ArrowRight, ArrowForward } from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import { ZipGridController } from '../controller/ZipGridController';
import BusinessSelector from '../../resources/BusinessSelector';
import { Urls } from '../../resources/Urls';

class ZipGridComponent extends React.Component {
	constructor(props) {
		super(props);
		this.zipGridController = new ZipGridController();
		this.verify = new Verify();
		this.utils = new Utils();
		this.state = {
			page: 1,
			limit: 8,
			idBussines: sessionStorage.getItem('idBusiness') || '',
			zips: {
				total: 16,
				data: [
					{
						id: 0,
						idCoupon: {
							id: 0,
							discount: 0,
							description: ''
						},
						itemsNumber: 0,
						filePath: '',
						status: '',
						createdBy: 0,
						createdAt: 0,
						modifiedBy: 0,
						modifiedAt: 0
					}
				]
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		await this.loadData();
	}

	reload() {
		window.location.reload(false);
	}

	async loadData() {
		this.setState({ charging: 'yes' }, async () => {
			const body = await this.zipGridController.findByBussinesId({
				page: this.state.page - 1,
				limit: this.state.limit,
				idBussines: this.state.idBussines
			});

			this.setState({
				zips: body,
				charging: 'no'
			});
		});
	}

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	getNext = async (event) => {
		event.preventDefault();

		this.setState(
			{
				page: this.state.page + 1,
				charging: 'yes'
			},
			() => {
				this.loadData();
			}
		);
	};

	getPrev = async (event) => {
		event.preventDefault();

		this.setState(
			{
				page: this.state.page - 1,
				charging: 'yes'
			},
			() => {
				this.loadData();
			}
		);
	};

	renderBody() {
		console.log(this.state);
		return this.state.zips.list.map((d) => (
			<tr key={d.zipDTO.idzip}>
				<td>{d.idcupon.description}</td>
				<td>{d.idcupon.discount}</td>
				<td>{d.zipDTO.itemNumber}</td>
				<td>{d.zipDTO.status}</td>
				<td>
					{d.zipDTO.status === 'Listo' ? (
						<a
							className='btn btn-success'
							href={Urls.zipsApi.baseZip + d.zipDTO.filePath}
						>
							Descargar
						</a>
					) : (
						<button className='btn btn-success' disabled>
							Descargar
						</button>
					)}
				</td>
			</tr>
		));
	}

	renderCard() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div
							className='card shadow bg-dark text-white'
							style={{ minHeight: '75%' }}
						>
							<div className='card-header'>
								<h4 className='text-center'>Zips</h4>
							</div>

							<div className='card-body'>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div className='row'>
										<div className='col-9 col-xl-9 col-lg-9 col-md-9'>
											<BusinessSelector />
										</div>
										<div className='col-3 col-xl-3 col-lg-3 col-md-3'>
											<a href='/zipGrid' className='btn btn-primary pl-6'>
												<ArrowForward />
											</a>
										</div>
									</div>
								)}
								<div className='container-fluid'>
									{this.state.charging === 'no' ? (
										<>
											{this.state.zips.list.length !== 0 ? (
												<div className='row justify-content-center'>
													<div className='table-responsive py-3'>
														<table className='table table-dark table-striped '>
															<thead className='thead-dark'>
																<tr>
																	<th>Descripción</th>
																	<th>Descuento</th>
																	<th>Cantidad de cupones</th>
																	<th>Estado</th>
																	<th>Descargar</th>
																</tr>
															</thead>
															<tbody>{this.renderBody()}</tbody>
														</table>
														<div className='container-fluid'>
															<div className='row'>
																<div className='col-9 align-content-end'>
																	Página: {this.state.page} de{' '}
																	{Math.ceil(this.state.zips.total / 8)}
																</div>

																{Math.ceil(
																	this.state.zips.total / this.state.limit
																) === 1 ? (
																	<></>
																) : (
																	<>
																		<div className='col-1'>
																			{this.state.page === 1 ? (
																				<></>
																			) : (
																				<ArrowLeft onClick={this.getPrev} />
																			)}
																		</div>
																		<div className='col-1'>
																			{this.state.zips.total >
																			this.state.page * 8 ? (
																				<ArrowRight onClick={this.getNext} />
																			) : (
																				<></>
																			)}
																		</div>
																	</>
																)}
															</div>
														</div>
													</div>
												</div>
											) : (
												<div className='row align-items-center justify-content-center p-4'>
													<div className='col-6 text-center'>
														<h4>Parece que no hay zip.</h4>
														<h5>Prueba a generar cupones para su negocio.</h5>
													</div>
												</div>
											)}
										</>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div>{this.renderCard()}</div>
			</>
		);
	}
}

export default withRouter(ZipGridComponent);
