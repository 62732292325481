import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class ZipGridController {
	async findByBussinesId({ page, limit, idBussines }) {
		const res = await Client.POST({
			url: Urls.zipsApi.getStatus,
			alert: 'show',
			params: {
				id: idBussines,
				page: page,
				limit: limit
			}
		});
		return res;
	}
}
