import React from 'react';
import { withRouter } from 'react-router-dom';
import { Home, ArrowLeft, ArrowRight } from '@material-ui/icons';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Verify } from '../../utils/Verify.js';
import { StaticsController } from '../controller/StaticsController.js';
import './statistics.css';
import Chart from 'react-apexcharts';

class CuponsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.staticsController = new StaticsController();
		this.verify = new Verify();
		this.state = {
			activeDiv: 'cuponesGenerados',
			selectedOption: '1',
			expired: 0,
			generated: 0,
			currentPage: 0,
			totalPages: 0,
			dates: [],
			counts: [],
			statechart: {
				options: {
					chart: {
						id: 'basic-bar'
					},
					xaxis: {
						categories: []
					},
					dataLabels: {
						style: {
							colors: ['#160a09']
						}
					}
				},
				series: [
					{
						name: 'series-1',
						data: []
					}
				]
			},
			statechartDo: {
				series: [],
				options: {
					chart: {
						width: 380
					},
					labels: [],
					responsive: [
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]
				}
			}
		};
	}

	handleOptionChange = async (event) => {
		const { id } = this.props.match.params;
		const selectedOption = event.target.value;
		await this.setState({ selectedOption });
		this.setActiveDiv(this.state.activeDiv);
		this.fetchInitialData(id);
	};

	back = () => {
		window.history.back();
	};

	menu = () => {
		this.verify.MenuTypeUser(this.props.history);
	};

	componentDidMount() {
		const { id } = this.props.match.params;
		this.setActiveDiv('cuponesGenerados');

		this.fetchInitialData(id);
	}

	async fetchInitialData(id) {
		try {
			const res = await this.staticsController.cuponExpired(
				id,
				this.state.selectedOption
			);
			const res1 = await this.staticsController.cuponGeneret(
				id,
				this.state.selectedOption
			);
			if (res && res.data) {
				this.setState({ expired: res.data.length });
			}

			if (res1 && res1.data) {
				this.setState({ generated: res1.data.length });
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	fetchCouponsData = async (type) => {
		const { id } = this.props.match.params;
		let res;

		switch (type) {
			case 'cuponesSinVida':
				res = await this.staticsController.findAllActiveNF(
					id,
					this.state.selectedOption
				);
				break;
			case 'cuponesGenerados':
				res = await this.staticsController.cuponGeneret(
					id,
					this.state.selectedOption
				);
				break;
			case 'cuponesExpirados':
				res = await this.staticsController.cuponExpired(
					id,
					this.state.selectedOption
				);
				break;
			case 'cuponesUtilizados':
				res = await this.staticsController.cuponUsed(
					id,
					this.state.selectedOption
				);
				break;
			default:
				break;
		}

		if (res && res.data) {
			const groupedData = res.data.reduce((acc, obj) => {
				const date = new Date(
					type === 'cuponesGenerados' || type === 'cuponesUtilizados'
						? obj.createdAt
						: obj.createdAt
				);
				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const year = date.getFullYear();
				const formattedDate = `${year}-${month}-${day}`;

				if (!acc[formattedDate]) {
					acc[formattedDate] = 0;
				}

				acc[formattedDate]++;
				return acc;
			}, {});

			const dates = Object.keys(groupedData).reverse();
			const counts = Object.values(groupedData).reverse();
			const totalPages = Math.ceil(dates.length / 15) || 1;

			this.setState(
				{
					dates,
					counts,
					totalPages,
					currentPage: 0
				},
				() => {
					this.updateChart();
				}
			);
		}
	};

	setActiveDiv = async (divName) => {
		this.setState({ activeDiv: divName });
		await this.fetchCouponsData(divName);
	};

	handlePageChange = (direction) => {
		this.setState(
			(prevState) => {
				let newPage = prevState.currentPage + direction;
				if (newPage < 0) newPage = 0;
				if (newPage >= prevState.totalPages) newPage = prevState.totalPages - 1;
				return { currentPage: newPage };
			},
			() => {
				this.updateChart();
			}
		);
	};

	updateChart = () => {
		const { dates, counts, currentPage } = this.state;
		const start = currentPage * 15;
		const end = start + 15;

		this.setState({
			statechart: {
				...this.state.statechart,
				options: {
					...this.state.statechart.options,
					xaxis: {
						categories: dates.slice(start, end)
					}
				},
				series: [
					{
						name: 'series-1',
						data: counts.slice(start, end)
					}
				]
			}
		});
	};

	render() {
		const { activeDiv, statechart, selectedOption, currentPage, totalPages } =
			this.state;
		const commonStyle = {
			margin: 'auto',
			padding: '20px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#fff', // Default background color
			color: '#000',
			textAlign: 'center',
			boxSizing: 'border-box'
		};

		const divStyles = {
			cuponesSinVida: { ...commonStyle, backgroundColor: '#d4edda' }, // Green background
			cuponesGenerados: { ...commonStyle, backgroundColor: '#fefefe' }, // White background
			cuponesExpirados: { ...commonStyle, backgroundColor: '#f8d7da' }, // Red background
			cuponesUtilizados: { ...commonStyle, backgroundColor: '#d1ecf1' } // Blue background
		};

		return (
			<div className='row justify-content-center h-100'>
				<div className='col-lg-12 align-self-center'>
					<div
						className='card shadow bg-dark text-white'
						style={{ minHeight: '75%' }}
					>
						<div className='card-body'>
							<div className='container-fluid'>
								<div id='businessName'>
									{this.props.location.state.businessName}
								</div>
								<br />
								<div className='row'>
									<div id='list-right'>
										<ul>
											<li onClick={() => this.setActiveDiv('cuponesSinVida')}>
												Cupones sin vida
											</li>
											<li onClick={() => this.setActiveDiv('cuponesGenerados')}>
												Cupones generados
											</li>
											<li onClick={() => this.setActiveDiv('cuponesExpirados')}>
												Cupones expirados
											</li>
											<li
												onClick={() => this.setActiveDiv('cuponesUtilizados')}
											>
												Cupones utilizados
											</li>
										</ul>
									</div>
									<div className='col-12 col-s-12 col-lg-7 col-md-12'>
										<div
											style={divStyles[activeDiv]}
											className='col-12 col-md-12 col-lg-12 col-s-12'
										>
											<Chart
												options={statechart.options}
												series={statechart.series}
												className='col-12 col-md-12 col-lg-12 col-s-12'
												type='bar'
												height='360'
											/>
										</div>
										<div className='col-12 col-md-12 col-lg-12 col-s-12'>
											<ArrowLeft onClick={() => this.handlePageChange(-1)} />
											Página: {currentPage + 1} de {totalPages}
											<ArrowRight onClick={() => this.handlePageChange(1)} />
										</div>
										<div className='col-12 col-md-12 col-lg-12 col-s-12'>
											{['0', '12', '6', '3', '1'].map((value) => (
												<label key={value} style={{ marginLeft: '7px' }}>
													<input
														type='radio'
														value={value}
														checked={selectedOption === value}
														onChange={this.handleOptionChange}
													/>
													{value === '0'
														? 'Todo'
														: value === '12'
														? '1 año'
														: `${value} mes${value > 1 ? 'es' : ''}`}
												</label>
											))}
										</div>
									</div>
									<div className='col-12 col-md-12 col-lg-3 col-s-12'>
										<div className='aside'>
											<h3>Cupones generados</h3>
											<input
												type='text'
												name='inputbox'
												value={this.state.generated}
												readOnly
											/>
											<h3>Contacto</h3>
											<input
												type='text'
												name='inputbox'
												value={this.props.location.state.businessContact}
												readOnly
											/>
											<h3>Cupones expirados</h3>
											<input
												type='text'
												name='inputbox'
												value={this.state.expired}
												readOnly
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='card-footer'>
							<div className='row'>
								<div className='col-2'>
									<ArrowBackIos onClick={this.back} />
								</div>
								<div className='col-8'></div>
								<div className='col-2'>
									<Home onClick={this.menu} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(CuponsComponent);
