import React from 'react';
import { withRouter } from 'react-router-dom';
import { Search, Home, ArrowLeft, ArrowRight } from '@material-ui/icons';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Verify } from '../../utils/Verify';
import { StaticsController } from '../controller/StaticsController.js';
import { Utils } from '../../resources/Utils.js';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BussinesFormComponent from '../../../src/BusinessForm/component/BussinesFormComponent';

class HomeStaticsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.staticsController = new StaticsController();
		this.verify = new Verify();
		this.utils = new Utils();
		this.state = {
			tableData: {
				page: 0,
				limit: 8,
				text: '',
				column: 'default'
			},
			page: 0,
			cardValue: 0,
			data: {
				data: [],
				total: 0
			},
			form: [],
			filterBusiness: {
				data: [],
				total: 0
			},
			charging: 'no'
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}

		if (!this.verify.securityVerify('sudosu')) {
			this.props.history.push('/superAdminMenu');
		}

		await this.loadData();
	}

	async loadData(change) {
		this.setState({ charging: 'yes' }, async () => {
			const body = await this.staticsController.findFilter(
				this.state.tableData
			);
			let pagAux = this.state.page;

			if (change) {
				pagAux = 0;
			}

			this.setState({
				data: body,
				filterBusiness: body,
				page: pagAux,
				charging: 'no'
			});
		});
	}

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	getNext = async (event) => {
		event.preventDefault();

		let col = 'default';
		if (document.getElementById('busqueda In').value !== '') {
			col = document.getElementById('criterio').value;
		}

		this.setState(
			{
				page: this.state.page + 1,
				tableData: {
					...this.state.tableData,
					page: this.state.tableData.page + 1,
					column: col,
					text: document.getElementById('busqueda In').value
				},
				filterBusiness: {
					...this.state.filterBusiness,
					data: []
				}
			},
			() => {
				this.loaderFn();
			}
		);
	};

	loaderFn = async () => {
		return await this.loadData().then((body) => {
			this.setState({
				data: body,
				filterBusiness: body
			});
		});
	};

	getPrev = async (event) => {
		event.preventDefault();

		let col = 'default';
		if (document.getElementById('busqueda In').value !== '') {
			col = document.getElementById('criterio').value;
		}

		if (this.state.tableData.page > 0) {
			this.setState(
				{
					page: this.state.page - 1,
					tableData: {
						...this.state.tableData,
						page: this.state.tableData.page - 1,
						column: col,
						text: document.getElementById('busqueda In').value
					},
					filterBusiness: {
						...this.state.filterBusiness,
						data: []
					}
				},
				() => {
					this.loadData();
				}
			);
		} else {
			Utils.swalSuccess('No se puede obtener datos previos.');
		}
	};

	changeStateFinal = (data) => {
		this.setState({ cardValue: data.id, form: data });
	};

	filtrar = (e) => {
		let criterio = '';
		if (document.getElementById('criterio').value !== 'default') {
			if (document.getElementById('busqueda In').value === '') {
				criterio = 'default';
			} else {
				criterio = document.getElementById('criterio').value;
			}
			this.setState(
				{
					page: 0,
					tableData: {
						...this.state.tableData,
						column: criterio,
						text: document.getElementById('busqueda In').value,
						page: 0
					},
					filterBusiness: {
						...this.state.filterBusiness,
						data: []
					}
				},
				() => {
					this.loadData(true);
				}
			);
		} else {
			if (document.getElementById('busqueda In').value !== '') {
				Utils.swalError('¡Seleccione un criterio primero!');
			}
		}
	};

	renderBody() {
		return this.state.filterBusiness.data.map((d) => (
			<tr key={d.id}>
				<td>{d.name}</td>
				<td>{d.description}</td>
				<td>{d.phone}</td>
				<td>{d.contacEmail}</td>
				<td>{d.catalogCatVO.description}</td>
				<td>
					<AssessmentIcon
						onClick={() => this.verCupones(d.id, d.name, d.contacEmail)}
					>
						{' '}
						Ver
					</AssessmentIcon>
				</td>
			</tr>
		));
	}

	renderCard() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div
							className='card shadow bg-dark text-white'
							style={{ minHeight: '75%' }}
						>
							<div className='card-header'>
								<h4 className='text-center'>Negocios</h4>
							</div>

							<div className='mt-3 col-sm-12 col-md-12 col-lg-12'>
								<div className='row'>
									<div className='col-lg-8'>
										<div className='input-group'>
											<div className='col-10 p-0'>
												<input
													type='text'
													className='form-control'
													id='busqueda In'
													placeholder='Ingrese su criterio a buscar.'
													aria-describedby='busqueda'
													onChange={this.filtrar}
													required
												/>
											</div>

											<div className='col-2 p-0 m-0'>
												<div className='input-group-prepend'>
													<span className='input-group-text' id='busqueda'>
														<Search />
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-4 mt-lg-0 mt-2'>
										<select
											className='form-select'
											defaultValue='default'
											aria-label='Default select example'
											id='criterio'
										>
											<option value='default'>Seleccione un criterio</option>
											<option value='name'>Nombre</option>
											<option value='slogan'>Categoria</option>
											<option value='phone'>Telefono</option>
											<option value='email'>Email</option>
										</select>
									</div>
								</div>
							</div>

							<div className='card-body'>
								<div className='container-fluid'>
									{this.state.charging === 'no' ? (
										<>
											{this.state.filterBusiness.data.length !== 0 ? (
												<div className='row justify-content-center mt-3'>
													<div className='table-responsive'>
														<table className='table table-dark table-striped '>
															<thead className='thead-dark'>
																<tr>
																	<th>Nombre</th>
																	<th>Descripción</th>
																	<th>Teléfono</th>
																	<th>Email</th>
																	<th>Categoría</th>

																	<th>Estadisticas</th>
																</tr>
															</thead>
															<tbody>{this.renderBody()}</tbody>
														</table>
													</div>
													<div className='container-fluid row'>
														<div className='col-9 align-content-end'>
															Página: {this.state.page + 1} de{' '}
															{Math.ceil(this.state.data.total / 8)}
														</div>
														<div className='col-1'>
															{this.state.page === 0 ? (
																<></>
															) : (
																<ArrowLeft onClick={this.getPrev} />
															)}
														</div>
														<div className='col-1'>
															{this.state.data.total >
															(this.state.page + 1) * 8 ? (
																<ArrowRight onClick={this.getNext} />
															) : (
																<></>
															)}
														</div>
													</div>
												</div>
											) : (
												<div className='row align-items-center justify-content-center p-4'>
													<div className='col-6 text-center'>
														<h4>Parece que no hay negocios.</h4>
														<h5>
															Prueba a insertar algún negocio o ingresar un
															criterio de búsqueda distinto.
														</h5>
													</div>
												</div>
											)}
										</>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	delete = async (id, name) => {
		Utils.swl({
			title: '¿Desea desactivar el negocio? ' + name + '?',
			showCancelButton: true,
			confirmButtonText: `Desactivar`,
			cancelButtonText: `Cancelar`
		}).then((result) => {
			if (result.isConfirmed) {
				this.staticsController.delete(id);
				this.loadData();
				this.render();

				setTimeout(() => {
					window.location.reload(true);
				}, 500);
			}
		});
	};

	verCupones = (id, name, contact) => {
		this.props.history.push(`/coupon/${id}`, {
			businessName: name,
			businessContact: contact
		});
	};

	render() {
		return (
			<>
				{this.state.cardValue < 1 ? (
					<div>{this.renderCard()}</div>
				) : (
					<div>{<BussinesFormComponent form={this.state.form} />}</div>
				)}
			</>
		);
	}
}

export default withRouter(HomeStaticsComponent);
