import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class StaticsController {
	async init(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findAll,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit
			}
		});
		return res;
	}

	async findCarrusel(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findCarrusel,
			params: {
				page: tableData.start,
				limit: tableData.limit
			}
		});
		return res;
	}

	async findFilter(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findFilter,
			params: {
				page: tableData.page,
				limit: tableData.limit,
				text: tableData.text,
				column: tableData.column
			}
		});
		return res;
	}

	async findAllActiveNF(ID, lastMonth) {
		const res = await Client.GET({
			url: Urls.cuponsApi.findAllActiveNF,
			params: {
				id: ID,
				lastMonth: lastMonth
			}
		});
		return res;
	}

	async cuponExpired(ID, lastMonth) {
		const res = await Client.GET({
			url: Urls.cuponsApi.cuponExpired,
			params: {
				id: ID,
				lastMonth: lastMonth
			}
		});
		return res;
	}

	async cuponGeneret(ID, lastMonth) {
		const res = await Client.GET({
			url: Urls.cuponsApi.cuponGeneret,
			params: {
				id: ID,
				lastMonth: lastMonth
			}
		});
		return res;
	}

	async cuponUsed(ID, lastMonth) {
		const res = await Client.GET({
			url: Urls.cuponsApi.cuponUsed,
			params: {
				id: ID,
				lastMonth: lastMonth
			}
		});
		return res;
	}
}
